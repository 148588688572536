<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card class="mb-0">
            <h5>
              {{new Intl.DateTimeFormat().format(new Date(item.created_at))}} - {{item.created_by}} - {{item.function}} - {{item.reason}}
            </h5>
            <div v-if="item.reason === 'other' && item.reasonother">
              other reason: {{item.reasonother}}
            </div>
            <div v-if="item.reason === 'sales support' || item.reason === 'engagement support'">
              opportunity size in USD: {{item.size}}<br />
              {{item.sector}} - {{item.industrygroup}} - {{item.industry}} - {{item.subindustry}}
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'ReportingmiSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
